<template>
  <div class="bg-peacock-700 flex items-center justify-between py-3 text-sm text-white">
    <div class="inline-flex items-center md:mx-auto">
      <img
        src="~assets/img/supersizing/goboony.svg"
        class="h-10 w-10"
      >
      <YscIconsArrowRight class="bg-peacock-700 z-40 -mx-1 h-4 w-4 rounded-full pl-0.5" />
      <img
        src="~assets/img/supersizing/yescapa.svg"
        class="h-10 w-10"
      >
      <div class="ml-4 flex flex-col">
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="block"
          v-html="$t('components.app_search_supersizing_banner.welcome_html')"
        />
        <!-- eslint-enable vue/no-v-html -->
        <a
          class="text-peacock-300 hover:text-peacock-200 cursor-pointer text-xs font-semibold"
          @click="$emit('read-more')"
        >{{ $t('commons.actions.know_more') }}<YscIconsArrowRight class="inline h-4 w-4" /></a>
      </div>
    </div>
    <button>
      <YscIconsDelete
        class="h-5 w-5"
        @click="$emit('close')"
      />
    </button>
  </div>
</template>
